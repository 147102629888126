import React from "react"
import { inject, observer } from "mobx-react"
import { Menu, Icon } from "antd"
import history from "../../history"
const org = process.env.REACT_APP_ORG

const sideMenuOptions = [
  { key: "/", icon: "home", text: "Home", accessLevel: 60,  },
  {
    key: "/accounting",
    icon: "calculator",
    text: "Accounting",
    accessLevel: 60,
  },
  { key: "/bills/list", icon: "container", text: "Bills", accessLevel: 60 },
  { key: "/sales/list", icon: "area-chart", text: "Sales", accessLevel: 30 },
  {
    key: "/inventory/dashboard",
    icon: "tags-o",
    text: "Inventory",
    accessLevel: 30,
  },
  {
    key: "/purchasing/purchaseorder",
    icon: "barcode",
    text: "Puchasing",
    accessLevel: 60,
  },
  {
    key: "/operations/dashboard",
    icon: "tool",
    text: "Operations",
    accessLevel: 20,
  },
  {
    key: "/quality/production",
    icon: "solution",
    text: "Quality",
    accessLevel: 30,
  },
  {
    key: "/reports/dashboard",
    icon: "pie-chart",
    text: "Reports",
    accessLevel: 45,
  },
  {
    key: "/settings/system",
    icon: "setting",
    text: "Settings",
    accessLevel: 100,
  },
]

class SideMenu extends React.Component {
  componentDidMount() {
    this.props.commonStore.updateSelectedKey(window.location.pathname)
  }

  handleClick = (menu) => {
    if (menu.key === "/accounting") {
      if (org === "pat") {
        window.open("https://accounting.pat-erp.com/", "_blank")
      } else {
        window.open("https://accounting.crf-erp.com/", "_blank")
      }
    } else {
      if (
        menu.key === "/inventory/dashboard" &&
        this.props.authStore.user.accessLevel <= 30
      ) {
        this.props.commonStore.updateSelectedKey("/inventory/finishedproduct")
        history.push("/inventory/finishedproduct")
      } else if (
        menu.key === "/operations/dashboard" &&
        this.props.authStore.user.accessLevel <= 30
      ) {
        this.props.commonStore.updateSelectedKey("/operations/productionorder")
        history.push("/operations/productionorder")
      } else {
        this.props.commonStore.updateSelectedKey(menu.key)
        history.push(menu.key)
      }
    }
  }

  showMenu = (userAccessLevel, menuAccessLevel, userRole, menuItem) => {
    if (userAccessLevel >= menuAccessLevel) {
      // override per Gina & Allen, don't show inventory to sales
      // 12.17.2023 - adding inventory dashboard to sales per Allen
      // if (menuItem === "/inventory/dashboard" && userRole === "sales") {
      //   return false
      // }
      if (menuItem === "/bills/list" && userRole === "sales") {
        return false
      }
      if (menuItem === "/purchasing/purchaseorder" && userRole === "sales") {
        return false
      }
      return true
    }
    return false
  }

  render() {
    const { branchLocationID } = this.props.authStore
    return (
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[window.location.pathname]}
        onClick={this.handleClick}
      >
        {sideMenuOptions.filter(row => row.hide ? row.hide.includes(Number(branchLocationID)) ? false : true : true).map((option) => {
          if (this.showMenu(this.props.authStore.user.accessLevel, option.accessLevel, this.props.authStore.user.role, option.key)) {
            return (
              <Menu.Item key={option.key}>
                <Icon type={option.icon} />
                <span className="nav-text">{option.text}</span>
              </Menu.Item>
            )
          }
          return null
        })}
      </Menu>
    )
  }
}

export default inject("commonStore", "authStore")(observer(SideMenu))
