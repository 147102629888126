import React from "react"
import { inject, observer } from "mobx-react"
import { Menu, Icon, Avatar, Modal, Button, message } from "antd"
import SignatureCanvas from "react-signature-canvas"
import fetch from "isomorphic-fetch"
import { notification } from "antd"
import { callApi } from "../../libs/api"

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID

class UserMenu extends React.Component {
  state = {
    visible: false,
  }

  sigPad = {}

  handleClick = ({ key }) => {
    if (key === "logout") {
      this.props.authStore.logout()
    } else if (key === "changepassword") {
      fetch(`https://${auth0Domain}/dbconnections/change_password`, {
        headers: { "content-type": "application/json" },
        method: "POST",
        body: JSON.stringify({
          client_id: auth0ClientId,
          email: this.props.authStore.user.email,
          connection: "Username-Password-Authentication",
        }),
      }).then((response) => {
        if (!response.ok) {
          notification["error"]({
            message: "Error ",
            description: "Error sending change password request ",
          })
        }

        notification["success"]({
          message: "Success",
          description:
            "Your change password request was successful, please check your email",
        })
      })
    } else if (key === "setsignature") {
      this.handleOpen()
    }
  }

  handleOpen = async () => {
    this.setState({ visible: true })
    try {
      const user = await callApi("/api/general/usersetting", "post", {
        where: { userid: this.props.authStore.user.email },
      })
      if (user && user.length > 0) {
        if (user[0].userSignature) {
          this.sigPad.fromDataURL(user[0].userSignature)
        }
      }
    } catch (error) {
      console.log(error)
      message.error("Error getting user record")
    }
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }

  handleOK = async () => {
    try {
      const signature = this.sigPad.toDataURL("image/png")
      await callApi("/api/users/signature", "put", { signature })
      this.setState({ visible: false })
    } catch (error) {
      console.log(error)
      message.error("Error saving signature")
    }
  }

  clear = () => {
    this.sigPad.clear()
  }

  render() {
    const { user } = this.props.authStore
    const { visible } = this.state

    return (
      <>
        <Menu mode="horizontal" onClick={this.handleClick}>
          <Menu.SubMenu
            title={
              <span>
                <Avatar src={user.picture} style={{ marginTop: 15 }} />
                <Icon type="down" style={{ marginLeft: 7, fontSize: 10 }} />
              </span>
            }
          >
            <Menu.Item key="changepassword">Change Password</Menu.Item>
            <Menu.Item key="setsignature">Set Signature</Menu.Item>
            <Menu.Item key="logout">Logout</Menu.Item>
          </Menu.SubMenu>
        </Menu>
        <Modal
          width={950}
          title="Set User Signature"
          visible={visible}
          onOk={this.handleOK}
          onCancel={this.handleCancel}
        >
          <div
            style={{ borderStyle: "solid", borderColor: "black", height: 250 }}
          >
            <Button siz="small" onClick={this.clear}>
              Clear
            </Button>
            <SignatureCanvas
              ref={(ref) => {
                this.sigPad = ref
              }}
              penColor="black"
              canvasProps={{ className: "sigPad" }}
            />
          </div>
        </Modal>
      </>
    )
  }
}

export default inject("authStore")(observer(UserMenu))
