import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import Layout from "./Components/Layout"
import { ConfigProvider } from "antd"
import enUS from "antd/lib/locale-provider/en_US"

import { configure } from "mobx"
import { Provider } from "mobx-react"

import authStore from "./stores/auth"
import commonStore from "./stores/common"
import generalStore from "./stores/general"
import Raven from "raven-js"
import ErrorBoundary from "./ErrorBoundary"

const stores = {
  authStore,
  commonStore,
  generalStore,
}

Raven.config(
  "https://0e9ee32a9b614939b1f211acb4095892@sentry.io/1233128"
).install()

window._____APP_STATE_____ = stores
configure({ enforceActions: "always" })

const App = () => (
  <ErrorBoundary>
    <Provider {...stores}>
      <ConfigProvider locale={enUS}>
        <Layout />
      </ConfigProvider>
    </Provider>
  </ErrorBoundary>
)

ReactDOM.render(<App />, document.getElementById("root"))
navigator.serviceWorker.getRegistrations().then(function (registrations) {
  for (let registration of registrations) {
    registration.unregister()
  }
})
