import React from "react"
import { Menu, Icon } from "antd"
import { inject, observer } from "mobx-react"
import history from "../../history"

class ContextMenu extends React.Component {
  handleClick = (item) => {
    this.props.commonStore.updateSelectedKey(item.key)
    history.push(item.key)
  }

  render() {
    const { menuItems, selectedKey } = this.props.commonStore
    const { branchLocationID } = this.props.authStore

    return (
      <Menu
        onClick={this.handleClick}
        mode="horizontal"
        selectedKeys={[selectedKey]}
      >
        {menuItems
          .filter(row => row.hide ? row.hide.includes(Number(branchLocationID)) ? false : true : true)
          .map((menu) => {
            if (this.props.authStore.user.accessLevel >= menu.accessLevel) {
              if (menu.subMenu && menu.subMenu.length > 0) {
                return (
                  <Menu.SubMenu
                    key={menu.link}
                    title={
                      <span>
                        <Icon type={menu.icon} />
                        {menu.title}
                      </span>
                    }
                  >
                    {menu.subMenu.map(
                      (sub) =>
                        this.props.authStore.user.accessLevel >=
                          sub.accessLevel && (
                          <Menu.Item key={sub.link}>
                            {sub.icon && <Icon type={sub.icon} />}
                            {sub.title}
                          </Menu.Item>
                        )
                    )}
                  </Menu.SubMenu>
                )
              } else {
                return (
                  <Menu.Item key={menu.link}>
                    {menu.icon && <Icon type={menu.icon} />}
                    {menu.title}
                  </Menu.Item>
                )
              }
            }
            return null
          })}
      </Menu>
    )
  }
}

export default inject("commonStore", "authStore")(observer(ContextMenu))
