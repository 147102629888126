import {
  observable,
  computed,
  action,
  runInAction,
  toJS,
  makeObservable,
} from "mobx"
import models from "./models"
import { callApi } from "../libs/api"
import { updateListById } from "../libs/utils"

const defaultState = {
  list: [],
  selectedItem: {},
  isDirty: false,
  busy: false,
  errorMessage: "",
  saving: false,
}

class General {
  data = {
    rawItem: { ...defaultState },
    unitOfMeasure: { ...defaultState },
    rawItemUOM: { ...defaultState },
    rawItemType: { ...defaultState },
    finishedProductClass: { ...defaultState },
    finishedProduct: { ...defaultState },
    purchaseOrder: { ...defaultState },
    purchaseOrderDetail: { ...defaultState },
    poStatus: { ...defaultState },
    warehouse: { ...defaultState },
    receiverHeader: { ...defaultState },
    receiverDetail: { ...defaultState },
    recipeDetail: { ...defaultState },
    productionOrder: { ...defaultState },
    productionLog: { ...defaultState },
    batch: { ...defaultState },
    finishedInventory: { ...defaultState },
    sample: { ...defaultState },
    sampleTest: { ...defaultState },
    salesOrder: { ...defaultState },
    salesOrderDetail: { ...defaultState },
    load: { ...defaultState },
    loadDetail: { ...defaultState },
    location: { ...defaultState },
    inventoryAdjustment: { ...defaultState },
    inventoryAdjustmentDetail: { ...defaultState },
    inventoryTransfer: { ...defaultState },
    inventoryTransferDetail: { ...defaultState },
    bill: { ...defaultState },
    billDetail: { ...defaultState },
    company: { ...defaultState },
    ownedByCompany: { ...defaultState },
    customerProfile: { ...defaultState },
    conversionJob: { ...defaultState },
    conversionJobDetail: { ...defaultState },
    creditMemo: { ...defaultState },
    creditMemoDetail: { ...defaultState },
    salesUnitOfMeasure: { ...defaultState },
    receiverFreight: { ...defaultState },
    freightVendor: { ...defaultState },
    physicalInventory: { ...defaultState },
    blending: { ...defaultState },
    blendingProduct: { ...defaultState },
    blendingConsumption: { ...defaultState },
    blendingProduction: { ...defaultState },
    freightVendor: { ...defaultState },
    customerCategory: { ...defaultState },
    customerSegment: { ...defaultState },
    productSegment: { ...defaultState },
    salesperson: { ...defaultState },
    FinishedProductByLocation: { ...defaultState },
    RawItemByLocationID: { ...defaultState },
  }

  constructor() {
    makeObservable(this, {
      data: observable,
      purchaseOrderDetailSubTotal: computed,
      purchaseOrderDetailQuantityTotal: computed,
      billDetailTotal: computed,
      salesOrderDetailSubTotal: computed,
      salesOrderDetailQuantityTotal: computed,
      loadDetailQuantityTotal: computed,
      productionBatchQuantityTotal: computed,
      get: action,
      query: action,
      getItem: action,
      updateItemField: action,
      setSelectedItemFromList: action,
      addListItem: action,
      removeListItem: action,
      createSelectedItem: action,
      clearList: action,
      clearSelectedItem: action,
      save: action,
    })
  }

  get purchaseOrderDetailSubTotal() {
    if (this.data.purchaseOrder.selectedItem.purchaseOrderDetail) {
      return this.data.purchaseOrder.selectedItem.purchaseOrderDetail.reduce(
        (total, item) => (total += item.quantity * item.cost),
        0
      )
    }
    return 0
  }

  get purchaseOrderDetailQuantityTotal() {
    if (this.data.purchaseOrder.selectedItem.purchaseOrderDetail) {
      return this.data.purchaseOrder.selectedItem.purchaseOrderDetail.reduce(
        (total, item) => (total += item.quantity * 1),
        0
      )
    }
    return 0
  }

  get billDetailTotal() {
    if (this.data.bill.selectedItem.billDetail) {
      return this.data.bill.selectedItem.billDetail.reduce(
        (total, item) => (total += item.amount * 1),
        0
      )
    }
    return 0
  }

  get salesOrderDetailSubTotal() {
    if (this.data.salesOrder.selectedItem.salesOrderDetail) {
      return this.data.salesOrder.selectedItem.salesOrderDetail.reduce(
        (total, item) => (total += item.quantity2 * item.unitPrice),
        0
      )
    }
    return 0
  }

  get salesOrderDetailQuantityTotal() {
    if (this.data.salesOrder.selectedItem.salesOrderDetail) {
      return this.data.salesOrder.selectedItem.salesOrderDetail.reduce(
        (total, item) => (total += item.quantity * 1),
        0
      )
    }
    return 0
  }

  get loadDetailQuantityTotal() {
    if (this.data.load.selectedItem.loadDetail) {
      return this.data.load.selectedItem.loadDetail.reduce(
        (total, item) => (total += item.quantity * 1),
        0
      )
    }
    return 0
  }

  get productionBatchQuantityTotal() {
    if (this.data.productionOrder.selectedItem.batch) {
      return this.data.productionOrder.selectedItem.batch.reduce(
        (total, item) => (total += item.quantity * 1),
        0
      )
    }
    return 0
  }

  async get(model) {
    this.data[model].errorMessage = ""
    this.data[model].busy = true
    try {
      const list = await callApi(`/api/general/${model}`)
      runInAction(() => {
        this.data[model].list = list
        this.data[model].busy = false
      })
    } catch (err) {
      console.error(err)
      runInAction(() => {
        this.data[model].errorMessage = err.message
        this.data[model].busy = false
      })
    }
  }

  async query(model, body, singleItem = false, endPointOverride = "") {
    return new Promise(async (resolve, reject) => {
      this.data[model].error = ""
      this.data[model].busy = true
      let url = `/api/general/${model}`
      let httpType = "post"
      if (endPointOverride.length > 0) {
        url = endPointOverride
        httpType = "get"
        body = undefined
      }
      try {
        const data = await callApi(url, httpType, body)
        runInAction(() => {
          if (singleItem) {
            this.data[model].selectedItem = data[0]
          } else {
            this.data[model].list = data
          }
          this.data[model].busy = false
          resolve()
        })
      } catch (err) {
        console.error(err)
        runInAction(() => {
          this.data[model].error = err.message
          this.data[model].busy = false
          reject(err)
        })
      }
    })
  }

  async getItem(model, id) {
    this.data[model].errorMessage = ""
    this.data[model].busy = true
    try {
      const item = await callApi(`/api/general/${model}/${id}`)
      runInAction(() => {
        this.data[model].selectedItem = item
        this.data[model].busy = false
      })
    } catch (err) {
      console.error(err)
      runInAction(() => {
        this.data[model].errorMessage = err.message
        this.data[model].busy = false
      })
    }
  }

  updateItemField(model, field, value) {
    return new Promise((resolve, reject) => {
      let v = value
      if (typeof(value) === "string") {
        v = value.replace(/'/g, '').replace(/;/g, '')
      }
      if (field.includes(".")) {
        const keys = field.split(".")
        if (keys.length === 3) {
          this.data[model].selectedItem[keys[0]][keys[1]][keys[2]] = v
          this.data[model].selectedItem[keys[0]][keys[1]].isDirty = true
        } else {
          throw new Error("nesting can only be one level deep")
        }
      } else {
        this.data[model].selectedItem[field] = v
      }
      this.data[model].isDirty = true
      resolve()
    })
  }

  setSelectedItemFromList(model, itemID) {
    const item = this.data[model].list.find((item) => item.ID === itemID)
    this.data[model].selectedItem = { ...item }
  }

  addListItem(model, listName, initial = {}) {
    let defaults = { ...models[listName], isDirty: true }
    defaults[`${model}ID`] = this.data[model].selectedItem.ID
    defaults = { ...defaults, ...initial }
    this.data[model].selectedItem[listName].push(defaults)
    this.data[model].isDirty = true
  }

  removeListItem(model, listName, item) {
    const selectedItem = this.data[model].selectedItem
    if (item.ID) {
      if (!selectedItem.deletedChildren) {
        selectedItem.deletedChildren = {}
      }
      if (!selectedItem.deletedChildren[listName]) {
        selectedItem.deletedChildren[listName] = []
      }
      selectedItem.deletedChildren[listName].push(item.ID)
    }
    selectedItem[listName].remove(item)
    this.data[model].isDirty = true
  }

  createSelectedItem(model, addToList = false) {
    return new Promise((resolve, reject) => {
      this.data[model].selectedItem = { ...models[model] }
      if (addToList) {
        this.data[model].list.push({ ...models[model] })
      }
      resolve()
    })
  }

  clearList(model) {
    this.data[model].list = []
  }

  clearSelectedItem(model) {
    this.data[model].selectedItem = {}
    this.data[model].isDirty = false
  }

  clearIsDirtyFlag(model) {
    runInAction(() => {
      this.data[model].isDirty = false
    })
  }

  async save(model, updateList = false, overrideURL = null) {
    return new Promise(async (resolve, reject) => {
      this.data[model].errorMessage = ""
      this.data[model].saving = true
      try {
        const body = toJS(this.data[model].selectedItem)
        if (body.ID === "new") {
          delete body.ID
        }
        // only send child records that have been touched
        for (const key in body) {
          if (Array.isArray(body[key])) {
            body[key] = body[key].filter((item) => item.isDirty)
          }
        }
        let item = await callApi(
          overrideURL ? overrideURL : `/api/general/${model}`,
          "put",
          body
        )
        runInAction(() => {
          this.data[model].selectedItem = { ...item }
          if (updateList) {
            const index = this.data[model].list.findIndex(
              (item) => item.ID === "new"
            )
            if (index > -1) {
              this.data[model].list[index] = { ...item }
            } else {
              this.data[model].list = updateListById(
                this.data[model].list,
                item
              )
            }
          }
          this.data[model].saving = false
          this.data[model].isDirty = false
          resolve()
        })
      } catch (err) {
        console.error(err)
        runInAction(() => {
          this.data[model].errorMessage = err.message
          this.data[model].saving = false
          reject(err)
        })
      }
    })
  }
}

export default new General()
