import React, { Suspense, lazy } from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import LoadingComponent from "./Components/LoadingComponent"

const Home = lazy(() => import("./Modules/Home"))
const Inventory = lazy(() => import("./Modules/Inventory"))
const Sales = lazy(() => import("./Modules/Sales"))
const Bills = lazy(() => import("./Modules/Bills"))
const Purchasing = lazy(() => import("./Modules/Purchasing"))
const Operations = lazy(() => import("./Modules/Operations"))
const Quality = lazy(() => import("./Modules/Quality"))
const Reports = lazy(() => import("./Modules/Reports"))
const Settings = lazy(() => import("./Modules/Settings"))
const Login = lazy(() => import("./Modules/Login"))
const Logout = lazy(() => import("./Modules/Login/Logout"))
const Callback = lazy(() => import("./Modules/Login/Callback"))

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        rest.authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

const NoMatch = () => <div>Page Not Found</div>

const Routes = props => (
  <Suspense fallback={<LoadingComponent />}>
    <Switch>
      <PrivateRoute
        authenticated={props.authenticated}
        exact
        path="/"
        component={Home}
      />
      <PrivateRoute
        authenticated={props.authenticated}
        path="/inventory"
        component={Inventory}
      />
      <PrivateRoute
        authenticated={props.authenticated}
        path="/bills"
        component={Bills}
      />
      <PrivateRoute
        authenticated={props.authenticated}
        path="/sales"
        component={Sales}
      />
      <PrivateRoute
        authenticated={props.authenticated}
        path="/purchasing"
        component={Purchasing}
      />
      <PrivateRoute
        authenticated={props.authenticated}
        path="/operations"
        component={Operations}
      />
      <PrivateRoute
        authenticated={props.authenticated}
        path="/quality"
        component={Quality}
      />
      <PrivateRoute
        authenticated={props.authenticated}
        path="/reports"
        component={Reports}
      />
      <PrivateRoute
        authenticated={props.authenticated}
        path="/settings"
        component={Settings}
      />
      <PrivateRoute
        authenticated={props.authenticated}
        path="/logout"
        component={Logout}
      />
      <Route path="/callback" component={Callback} />
      <Route path="/login" component={Login} />
      <Route component={NoMatch} />
    </Switch>
  </Suspense>
)

export default Routes
