import React from "react"
import logo from "../../Assets/logoSmall.png"
import logo2 from "../../Assets/logo2.png"
import crfLogo from "../../Assets/crflogo.png"
import crfLogoSamll from "../../Assets/crflogo_small.png"

const app_version = process.env.REACT_APP_VERSION
console.log(`app version: ${app_version}`)

const org = process.env.REACT_APP_ORG

const Version = ({ float, color }) => (
  <span style={{ color, fontSize: 8, float: float, marginTop: 5 }}>
    v: {app_version}
  </span>
)

export default ({ collapsed, darkMode }) => (
  <div className="logo" style={{ marginBottom: 40 }}>
    {collapsed && (
      <span>
        <img
          src={org === "pat" ? logo : crfLogoSamll}
          alt="logo"
          style={{ width: "90%" }}
        />
        <Version float="left" color="white" />
      </span>
    )}
    {!collapsed && (
      <span>
        {org === "pat" ? (
          <>
            <img src={logo} alt="logo" style={{ width: "30%" }} />{" "}
            <img src={logo2} alt="Pursell" />
          </>
        ) : (
          <img src={crfLogo} alt="logo" style={{ width: "100%" }} />
        )}

        <Version float="right" color="white" />
      </span>
    )}
  </div>
)
