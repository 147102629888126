import { observable, action, runInAction, makeObservable } from "mobx"
import Auth from "../libs/auth"
const org = process.env.REACT_APP_ORG

const auth = new Auth()

class AuthStore {
  authenticated = false
  errorMessage = ""
  redirectToReferrer = ""
  user = {}
  qualityTestingTab = "2"
  appVersion = "0.0.0"
  branchLocationID = org === "pat" ? 1 : 2
  disableLocation = false

  constructor() {
    makeObservable(this, {
      authenticated: observable,
      errorMessage: observable,
      redirectToReferrer: observable,
      user: observable,
      appVersion: observable,
      branchLocationID: observable,
      disableLocation: observable,
      isLoggedIn: action,
      setProfile: action,
      login: action,
      authorize: action,
      logout: action,
      setBranchLocation: action
    })

    this.isLoggedIn()
  }

  setBranchLocation = (value) => {
    runInAction(() => {
      this.branchLocationID = value
      localStorage.setItem("branchLocationID", value)
    })
  }

  setDisableLocation = (value) => {
    runInAction(() => {
      this.disableLocation = value
    })
  }

  setQualityTestingTab = (key) => {
    runInAction(() => {
      this.qualityTestingTab = key
    })
  }

  isLoggedIn = () => {
    this.authenticated = auth.isAuthenticated()
    if (this.authenticated) {
      auth.getProfile().then(
        (profile) => {
          this.setProfile(profile)
        },
        (error) => {
          // alert user about profile error
          runInAction(() => {
            this.errorMessage = "Error getting user profile"
          })
        }
      )
      this.getAppVersion()
      const bl = localStorage.getItem("branchLocationID")
      if (bl) {
        this.setBranchLocation(bl)
      }
    }
  }

  getAppVersion() {
    auth.getAppVersion().then(
      (systemSetting) => {
        this.setAppVersion(systemSetting)
      },
      (error) => {
        // alert user about profile error
        runInAction(() => {
          this.errorMessage = "Error getting app version"
        })
      }
    )
  }

  setAppVersion(systemSetting) {
    runInAction(() => {
      this.appVersion = systemSetting.appVersion
    })
  }

  setProfile(profile) {
    this.user = profile
  }

  toggleCollapsed = () => {
    runInAction(() => {
      this.user.collapsed = !this.user.collapsed
    })
  }

  login = () => {
    this.errorMessage = false
    auth.login()
  }

  authorize = () => {
    auth.handleAuthentication().then(
      () => {
        runInAction(() => {
          auth.getProfile().then(
            (profile) => {
              this.setProfile(profile)
              runInAction(() => {
                this.authenticated = true
                this.redirectToReferrer = profile.defaultHomePage
              })
            },
            (error) => {
              // alert user about profile error
              runInAction(() => {
                this.errorMessage = "Error getting user profile"
              })
            }
          )
          this.getAppVersion()
          const bl = localStorage.getItem("branchLocationID")
          if (bl) {
            this.setBranchLocation(bl)
          }          
        })
      },
      (error) => {
        // alert user of error
        runInAction(() => {
          this.errorMessage = "Error logging in"
        })
      }
    )
  }

  logout = () => {
    this.authenticated = false
    auth.logout()
    return Promise.resolve()
  }
}

export default new AuthStore()
