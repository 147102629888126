import React from "react"

export default function convertPropsToBody(props) {
  const body = {}

  if (props.where) {
    body["where"] = props.where
  }

  if (props.joins) {
    body["joins"] = props.joins
  }

  if (props.orderBy) {
    body["orderBy"] = props.orderBy
  }
  return body
}

export const searchWithHilite = (data, searchValue, searchColumn) => {
  return data
    .map(record => {
      let match = true
      const reg = new RegExp(searchValue, "gi")
      match = record[searchColumn] ? record[searchColumn].toString().match(reg) : null
      if (!match) {
        return null
      }
      return {
        ...record,
        [searchColumn]: (
          <span>
            {record[searchColumn].toString().split(reg).map(
              (text, i) => 
                i > 0
                  ? [
                      <span key={i} className="highlight">
                        {match[0]}
                      </span>,
                      text
                    ]
                  : text
            )}
          </span>
        )
      }
    })
    .filter(record => !!record)
}